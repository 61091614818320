<template>
  <div>
    <div v-if="startsAt || eventUrl" class="mb-5">
      <h3 v-if="startsAt" class="font-semibold flex-align">
        <Icon class="mt-0.5" name="heroicons:calendar-days" />
        <span class="ml-1">{{ startsAt }}</span>
      </h3>
      <TextCopy
        v-if="eventUrl"
        :text="eventUrl"
        class="md:max-w-400px"
        :class="{ 'mt-1': startsAt }"
      />
    </div>

    <EventMetrics class="mb-10" />

    <OnboardSteps
      v-if="nextSteps"
      @click="handleClick"
      :loading="fetching"
      :disabled="saving"
      :steps="nextSteps"
      title="To-Do"
      class="mt-10"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { OnboardSteps } from "#components";
import EventMetrics from "./EventMetrics.vue";
import Event from "~/models/dobby/Event";
import type { OnboardStep } from "~/layers/admin/models";
import { filter } from "lodash";

export default defineComponent({
  components: { EventMetrics, OnboardSteps },
  props: {
    navigate: { type: Function as PropType<{ (to: string): void }> },
  },
  setup() {
    const { fetching, saving, original, model } = useSaveContext<Event>();

    const nextSteps = computed(() => original.value?.nextSteps);
    // const eventUrl = computed(() =>
    //   original.value.handle
    //     ? `https://orie.market/events/${original.value.handle}`
    //     : "#"
    // );

    const eventUrl = computed(() => model.value?.$url());

    const handle = computed(() => original.value.handle);

    const startsAt = computed(() => {
      const occurrences = original.value.occurrences || [];
      const dates = filter(occurrences.map((o) => o.starts_at!));
      const date = findRelevantDate(dates);

      if (date) {
        return dayjs(date).format("LLLL");
      }
    });

    return {
      fetching,
      saving,
      nextSteps,
      eventUrl,
      handle,
      startsAt,
    };
  },
  methods: {
    handleClick(step: OnboardStep) {
      if (!this.navigate || !step.handle) return;
      const stepHandle = step.handle.toLowerCase();
      if (stepHandle.includes("ticket")) {
        this.navigate("tickets");
      } else if (
        stepHandle.includes("image") ||
        stepHandle.includes("description")
      ) {
        this.navigate("details");
      } else if (
        stepHandle.includes("time") ||
        stepHandle.includes("date") ||
        stepHandle.includes("venue")
      ) {
        this.navigate("location");
      } else if (stepHandle.includes("publish")) {
        this.navigate("publish");
      }
    },
  },
});
</script>
