import { jsPDF } from "jspdf";
import { createMap } from "../map";
import { usePickSeatsStore } from "./store";
import type { MapSaveState } from "../store";

export type EventMapExportFormat = "a0" | "a1" | "a2" | "a3" | "a4" | "a5";

const dimensions: Record<EventMapExportFormat, [number, number]> = {
  a0: [1189, 841],
  a1: [841, 594],
  a2: [594, 420],
  a3: [420, 297],
  a4: [297, 210],
  a5: [210, 148],
};

type DoExportOptions = {
  target?: string | HTMLElement;
  map: MapSaveState;
  format?: EventMapExportFormat;
  resolution?: number;
};

export const createExporter = () => {
  const exporting = ref(false);
  const store = usePickSeatsStore();

  const exportName = "map.pdf";

  const createTarget = () => {
    const target = document.createElement("div");
    target.style.width = "800px";
    target.style.height = "600px";
    target.style.position = "absolute";
    target.style.top = "-9999px"; // Hide offscreen
    document.body.appendChild(target);
    return target;
  };

  const doExport = async (options: DoExportOptions) => {
    const target = options.target || createTarget();
    const map = createMap(target);
    store.initStore(options.map, [], 10, []);
    map.initMap();

    return new Promise<void>((resolve) => {
      exporting.value = true;

      const format = options.format || "a0";
      const dim = dimensions[format];
      const res = options.resolution || 72;
      const width = Math.round((dim[0] * res) / 25.4);
      const height = Math.round((dim[1] * res) / 25.4);
      const size = map.map.getSize()!;
      const viewResolution = map.map.getView().getResolution()!;

      map.map.once("rendercomplete", function () {
        const mapCanvas = document.createElement("canvas")!;
        const mapContext = mapCanvas.getContext("2d")!;

        mapCanvas.width = width;
        mapCanvas.height = height;

        Array.prototype.forEach.call(
          document.querySelectorAll(".ol-layer canvas"),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
              const transform = canvas.style.transform;
              // Get the transform parameters from the style's transform matrix
              const matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(",")
                .map(Number);
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);
        const pdf = new jsPDF("landscape", undefined, format);
        pdf.addImage(
          mapCanvas.toDataURL("image/jpeg"),
          "JPEG",
          0,
          0,
          dim[0],
          dim[1]
        );

        pdf.save(exportName);

        // Reset original map size
        map.map.setSize(size);
        map.map.getView().setResolution(viewResolution);
        // document.body.style.cursor = "auto";

        // Tear down
        map.map.setTarget(undefined); // Disconnect the map from the DOM
        map.map.dispose();
        if (!options.target) {
          document.body.removeChild(target as HTMLElement); // Remove the container
        }

        exporting.value = false;
        resolve();
      });

      // Set print size
      const printSize = [width, height];
      map.map.setSize(printSize);
      const scaling = Math.min(width / size[0], height / size[1]);
      map.map.getView().setResolution(viewResolution / scaling);
    });
  };

  return {
    exporting,
    doExport,
  };
};
