import { VSelect } from "vuetify/components";
import type { EventOccurrence } from "~/models/dobby/Event";

export default defineComponent({
  props: {
    modelValue: { type: String },
  },
  setup(props, ctx) {
    return () => {
      const { original } = useSaveContext<any>();
      const occurrences: EventOccurrence[] = original.value.occurrences || [];

      return h(VSelect, {
        modelValue: props.modelValue,
        "onUpdate:modelValue": (v) => ctx.emit("update:modelValue", v),
        clearable: true,
        items: occurrences.map((occurrence, i) => ({
          title: occurrence.name || `Occurrence #${i}`,
          value: occurrence.id,
        })),
      });
    };
  },
});
