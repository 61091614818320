<template>
  <v-navigation-drawer v-model="open" width="800" location="right" temporary>
    <v-sheet class="px-4 pb-10 pt-2">
      <v-btn @click="open = false" icon class="mb-5">
        <v-icon>close</v-icon>
      </v-btn>
      <div>
        <v-icon size="small">bookmark</v-icon>
        <span class="caption">Event booking</span>
      </div>
      <v-card-actions class="px-0">
        <h3 class="mt-2 mb-4">{{ title }}</h3>
        <v-spacer />
        <!-- <v-btn v-if="booking" size="small">Refund</v-btn> -->
        <!-- <v-btn size="small">Admit</v-btn> -->
      </v-card-actions>
      <v-divider />
      <v-row class="my-4" no-gutters>
        <div>
          <div class="caption">Booked at</div>
          <div class="body-2">
            <DateValue :item="booking" item-key="booked_at" />
          </div>
        </div>
        <v-divider class="mx-4" vertical />
        <div>
          <div class="caption">Attended at</div>
          <div class="body-2">
            <DateValue :item="booking" item-key="attended_at" />
          </div>
        </div>
        <v-divider class="mx-4" vertical />
        <div>
          <div class="caption">Cancelled at</div>
          <div class="body-2">
            <DateValue :item="booking" item-key="cancelled_at" />
          </div>
        </div>
        <v-divider class="mx-4" vertical />
        <div>
          <div class="caption">Total tickets</div>
          <div class="subtitle-2">{{ tickets.length }}</div>
        </div>
      </v-row>

      <DataTable
        class="mt-10"
        v-bind="table"
        @action:change-seats="changeSeats"
        @action:resend="resendTickets"
        :items="tickets"
        elevation="2"
        hide-default-footer
      />
    </v-sheet>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useToast } from "vue-toastification";
import { Date as DateValue } from "~/layers/admin/private/data-table/Column/components";
import type Event from "~/models/dobby/Event";
import { EventBookingTicket } from "~/models/dobby/Event";
import type { TicketItem } from "./types";
import { Rule } from "~/services/Rules";
import EventSeats from "~/components/pages/events/EventSeats.vue";

export default defineComponent({
  props: {
    // item: { type: Object as PropType<TicketItem> },
    modelValue: { type: Boolean, default: false },
  },
  components: { DateValue },
  setup() {
    const { original } = useSaveContext<Event>();
    const open = ref(false);
    const item = shallowRef<TicketItem>();

    const toast = useToast();
    const { wrapConfirmInput } = useConfirm();
    const { handle: handleEvent } = useNamespacedEvent("event-attendees");

    const {
      loading: resendingTickets,
      mutate: resendTickets_,
      onDone,
      onError,
    } = useMutation(gql`
      mutation ($code: String!, $email: Email) {
        resendEventBookingTickets(code: $code, email: $email) {
          code
        }
      }
    `);

    const handle = computed(() => original.value?.handle);

    handleEvent("click:row", (ticketItem: TicketItem) => {
      item.value = ticketItem;
      open.value = true;
    });

    const resendTickets = wrapConfirmInput(
      (input, ticket) => {
        return resendTickets_({
          ...input,
          code: ticket.code,
        });
      },
      (ticket: EventBookingTicket) => ({
        title: "Resend tickets",
        inputs: defineFormInputs({
          email: {
            type: "text",
            rules: Rule.group([Rule.required(), Rule.email()]),
          },
        }),
        inputsDefaults: {
          email: ticket.email,
        },
      })
    );

    onDone(() => {
      toast.success("Tickets resent");
    });

    onError(() => {
      toast.error("Failed to resend tickets");
    });

    return {
      resendingTickets,
      resendTickets,
      open,
      handle,
      item,
    };
  },
  computed: {
    tickets() {
      return this.item?.booking.tickets || [];
    },
    title(): any {
      if (this.item?.booking.order) {
        return `Booking ${this.item?.booking.order.number}`;
      } else if (this.item) {
        return `Booking #${this.item.id}`;
      }
    },
    booking() {
      return this.item?.booking;
    },
    canChangeSeats() {
      return (
        !this.item?.booking.cancelled_at && !this.item?.booking.attended_at
      );
    },
    table() {
      return defineTable({
        actionsNamespace: "event-ticket",

        columns: [
          { title: "Ref.", sortable: true, key: "code" },
          { title: "Name", sortable: true, key: "name" },
          { title: "Email", sortable: true, key: "email", type: "email" },
          { title: "Section", sortable: true, key: "section" },
          { title: "Ticket", key: "eventTicket.name" },
          {
            title: "Seats",
            key: "seats",
            component: dataTableComponent(EventSeats, {
              useItemValue: true,
              usePropKey: "seats",
            }),
          },
        ],

        itemActions: execFn(() => {
          const actions = [
            {
              title: "Resend tickets",
              key: "resend",
              icon: "send",
              tip: "Resend tickets",
            },
          ];

          if (this.canChangeSeats) {
            return [
              ...actions,
              {
                title: "Change seats",
                key: "change-seats",
                icon: "event_seat",
                tip: "Change seats",
              },
            ];
          }

          return actions;
        }),
      });
    },
  },
  methods: {
    changeSeats(ticket: EventBookingTicket) {
      this.$router.push({
        name: "events-handle-change-seats-code",
        params: {
          handle: this.handle,
          code: ticket.code,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  height: 100%;
  width: 100%;
}
</style>
