<template>
  <v-dialog v-model="dialog" width="1000" persistent>
    <template v-slot:activator="{ props }">
      <v-combobox
        v-bind="props"
        v-model="seats"
        :error-messages="errorMessages"
        :disabled="disabled"
        chips
        multiple
        readonly
        clearable
      />
    </template>

    <template v-slot:default>
      <v-sheet class="py-4 relative" height="80vh">
        <div class="absolute inset-0 h-60px">
          <div class="h-full flex-align justify-end px-2">
            <v-btn @click="close" icon="close" />
          </div>
        </div>
        <PickSeats
          class="h-100"
          v-if="eventMap"
          @seats-selected="select"
          :event-map="eventMap"
          :available-seats="availableSeats"
          :max-seats="maxSeats"
        />
      </v-sheet>
    </template>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";
import PickSeats from "./PickSeats.vue";

export default defineComponent({
  name: "EventMapPicker",
  components: { PickSeats },
  props: {
    modelValue: { type: Array as PropType<string[]> },
    disabled: Boolean,
    errorMessages: {},
    eventMap: { type: Object as PropType<any> },
    availableSeats: { type: Array as PropType<string[]>, default: () => [] },
    maxSeats: { type: Number },
  },
  setup(props) {
    const seats = useVModel(props, "modelValue");
    const dialog = ref(false);

    const close = () => {
      dialog.value = false;
    };

    const select = afterEach((value: string[]) => {
      seats.value = value;
    }, close);

    return {
      seats,
      dialog,
      select,
      close,
    };
  },
});
</script>
