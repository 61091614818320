<template>
  <v-card>
    <div class="pa-4">
      <div class="form-section-heading">Publish event</div>
      <Publication type="event" :where="where" />
    </div>
    <v-divider />
    <v-dynamic-form
      class="pa-4"
      v-model="data"
      v-model:valid="valid.publication"
      :disabled="!canEdit"
      :inputs="inputs"
      hide-actions
    />
  </v-card>
</template>

<script lang="ts">
import { CheckBox } from "#components";
import { Rule } from "~/services/Rules";
import Publication from "../../shared/publication/Publication.vue";
import type Event from "~/models/dobby/Event";

export default defineComponent({
  components: { Publication },
  setup() {
    const { data, canEdit, getValid } = useSaveContext<Event>();

    const valid = getValid();

    return {
      data,
      canEdit,
      valid,
    };
  },
  computed: {
    where() {
      return {
        handle: String(this.$route.params.handle),
      };
    },
  },
  data: () => ({
    inputs: defineFormInputs({
      active: {
        hideName: true,
        component: CheckBox,
        props: {
          title: "Active",
          subtitle:
            "This does not affect event verification. However, inactive events are not publicly visible.",
        },
      },
    }),
  }),
});
</script>
