import { VSelect } from "vuetify/components";
import { omit } from "lodash";

const options = [
  { title: "1 hour before", value: "hours:1" },
  { title: "3 hours before", value: "hours:3" },
  { title: "6 hours before", value: "hours:6" },
  { title: "2 days before", value: "days:2" },
  { title: "3 days before", value: "days:2" },
  { title: "5 days before", value: "days:5" },
  { title: "1 week before", value: "weeks:1" },
  { title: "2 weeks before", value: "weeks:2" },
  { title: "3 weeks before", value: "weeks:3" },
  { title: "Always", value: "always" },
];

export default defineComponent({
  name: "CountDownSelect",
  props: {
    ...VSelect.props,
    modelValue: { type: String },
    placeholder: { type: String, default: "None" },
    noneTitle: { type: String, default: "None" },
    noneValue: {},
  },
  computed: {
    props() {
      return omit(this.$props, [
        "modelValue",
        "items",
        "noneTitle",
        "itemValue",
      ]);
    },
    items_() {
      return [{ title: this.noneTitle, value: this.noneValue }, ...options];
    },
  },
  render() {
    return h(
      VSelect,
      {
        ...this.props,
        items: this.items_,
        itemValue: "value",
        modelValue: this.modelValue,
        "onUpdate:modelValue": (v: string) => {
          this.$emit("update:modelValue", v);
        },
      },
      {
        selection: ({ item }: any) => {
          return h("span", item ? item.title : this.noneTitle);
        },
      }
    );
  },
});
