<template>
  <div class="page-container">
    <AppContextualSaveBar />
    <PageHeading
      class="mb-4"
      :title="title"
      :actions="actions"
      actions-namespace="event"
    >
      <template #append-title>
        <PublishableStatus
          v-if="!fetching"
          :item="original"
          size="x-small"
          class="ml-2"
        />
      </template>
    </PageHeading>

    <StaAlert v-if="isLive" class="mb-4" />

    <SaveContextMenuContent :menu="menu" ref="content" class="mb-4" remember />

    <div class="flex gap-2">
      <v-spacer />
      <v-btn elevation="1" :disabled="!canEdit">Archive</v-btn>
      <v-btn
        @click="del"
        :loading="deleting"
        :disabled="!canEdit"
        color="red"
        elevation="1"
        >Delete
      </v-btn>
      <v-btn
        @click="save"
        :disabled="!canSave"
        :loading="saving"
        color="primary"
        elevation="1"
        >Save
      </v-btn>
    </div>

    <DetailersDownloader />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { get } from "lodash";
import Event from "~/models/dobby/Event";
import EventOverview from "@/components/forms/event/EventOverview.vue";
import EventDetails from "@/components/forms/event/EventDetails.vue";
import EventLocation from "@/components/forms/event/EventLocation.vue";
import EventTickets from "@/components/forms/event/EventTickets.vue";
import EventAttendees from "@/components/forms/event/EventAttendees.vue";
import EventAnalytics from "@/components/forms/event/EventAnalytics.vue";
import EventAdvanced from "@/components/forms/event/EventAdvanced.vue";
import EventPublication from "@/components/forms/event/EventPublication.vue";
import StaAlert from "@/components/forms/event/StaAlert.vue";
import { EventOccurrenceType } from "~/layers/admin/models";
import { startStaSession } from "~/components/pages/sta";
import DetailersDownloader from "~/components/pages/events/DetailersDownloader.vue";

export default defineComponent({
  components: { StaAlert, DetailersDownloader },
  setup() {
    const content = ref<any>();
    const route = useRoute();
    const router = useRouter();
    const { wrapLoading } = useLoading();
    const { handle } = useNamespacedEvent("event");

    const {
      canSave,
      save,
      saving,
      fetching,
      original,
      canEdit,
      onResult,
      onBeforeSave,
    } = useSaveContext<Event>({
      query: () => {
        const query = Event.select("*")
          .include("image")
          .include("gallery")
          .include("type", ["id", "name"])
          .include("schedule", ["*"])
          .include("availability", ["*"])
          .include("nextSteps", ["*"])
          .include("agenda", ["*"])
          .include("faqs", ["*"])
          .include("occurrences", (query) => {
            return query.selectAll().include(["bookingLocation"]);
          })
          .include("sections", (query) => {
            return query.selectAll().include("tickets", (query) => {
              return query
                .selectAll()
                .include(["pricing", "comparison_pricing"]);
            });
          })
          .include("bookings", (query) => {
            return query
              .selectAll()
              .include("tickets", (query) =>
                query.selectAll().include("eventTicket")
              )
              .include("order", (query) =>
                query.select("number", "id", "objectName")
              );
          })
          .include("discounts", (query) => {
            return query
              .selectAll()
              .include("tickets", (query) => query.selectAll());
          });

        return query.findUnique({ handle: String(route.params.handle) });
      },
    });

    const { del, deleting } = useSaveContextDeleteModel({
      model: Event,
      routeOnDelete: { name: "events" },
    });

    onResult((data) => {
      if (data.occurrences) {
        data.occurrences = (data.occurrences as any[]).map((occur) => {
          if (occur.starts_at && occur.ends_at) {
            occur.date = [
              parseDatetime(occur.starts_at).toDate(),
              parseDatetime(occur.ends_at).toDate(),
            ];
          }
          return occur;
        });
      }
      return data;
    });

    onBeforeSave((input) => {
      if (input.data.occurrences) {
        input.data.occurrences = (input.data.occurrences as any[]).map(
          (occur) => {
            if (occur.date) {
              occur.starts_at = formatToDatetime(parseDateLike(occur.date[0]));
              occur.ends_at = formatToDatetime(parseDateLike(occur.date[1]));
            }
            return occur;
          }
        );
      }
      return input;
    });

    const title = computed(
      () => original.value.name || String(route.params.handle)
    );

    const navigate = (key: string) => {
      content.value?.open(key);
    };

    const menu = defineSaveContextMenu(() => [
      {
        title: "Overview",
        key: "overview",
        component: EventOverview,
        primary: true,
        props: {
          navigate,
        },
      },
      {
        title: "Details",
        key: "details",
        component: EventDetails,
      },

      {
        title: match(original.value.occurrence_type, {
          [EventOccurrenceType.SESSION]: "Sessions & Locations",
          [EventOccurrenceType.TOUR]: "Tour Locations",
          default: "Date & Location",
        }),
        key: "location",
        component: EventLocation,
      },
      {
        title: "Tickets",
        key: "tickets",
        component: EventTickets,
      },
      // { title: 'Amenities', key: 'amenities' },
      {
        title: "Attendees",
        key: "attendees",
        component: EventAttendees,
      },
      {
        title: "Analytics",
        key: "analytics",
        component: EventAnalytics,
      },
      {
        title: "Advanced",
        key: "advanced",
        component: EventAdvanced,
      },
      {
        title: "Publish",
        key: "publish",
        component: EventPublication,
        color: "primary",
        primary: true,
      },
    ]);

    const preview = usePreviewModel(original);

    const sta = wrapLoading(async () => {
      const occurrenceId = get(original.value, "occurrences.0.id");
      if (!occurrenceId) {
        return;
      }

      const handle = String(route.params.handle);
      const token = await startStaSession(occurrenceId);

      router.push({
        name: "events-handle-sta",
        params: { handle },
        query: { token },
      });
    });

    handle("preview", preview.preview);
    handle("sta", sta);

    const isLive = computed(() => {
      const occurrences = original.value.occurrences || [];
      const x = occurrences.map(
        (occur) =>
          !!occur.starts_at &&
          !!occur.ends_at &&
          isActivePeriod(occur.starts_at, occur.ends_at)
      );
      return someTrue(x);
    });

    const actions = computed(() => {
      return defineActions([
        {
          title: "Scan to Admit",
          key: "sta",
          icon: "mdi:barcode-scan",
          hidden: isLive.value,
          disabled: fetching.value,
        },
        // {
        //   title: "Download details",
        //   key: "download-details",
        //   icon: "carbon:download",
        // },
        {
          title: "Preview",
          key: "preview",
          icon: "visibility",
          loading: preview.loading.value,
        },
      ]);
    });

    return {
      content,
      menu,
      canSave,
      canEdit,
      deleting,
      del,
      save,
      saving,
      fetching,
      title,
      original,
      isLive,
      actions,
      sta,
    };
  },
});
</script>
