<template>
  <div>
    <div class="pa-4">
      <v-dynamic-form
        v-model="model"
        v-model:valid="validModel"
        :inputs="inputs"
      />
    </div>
    <v-divider />
    <div class="pa-4">
      <v-card-subtitle class="pa-0">Tickets</v-card-subtitle>
      <TicketsInput
        v-model="model.tickets"
        :parent-key="contentKey"
        class="mt-3"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { CheckBox } from "#components";
import { defineComponent } from "vue";
import type { IEventTicketSection } from "~/models/interfaces/Event";
import { Rule } from "~/services/Rules";
import TicketsInput from "./TicketsInput.vue";
import OccurrenceSelect from "./OccurrenceSelect";
import { EventOccurrenceType } from "~/layers/admin/models";

export default defineComponent({
  components: { TicketsInput },
  props: {
    contentKey: { type: String, required: true },
    modelValue: {
      type: Object as PropType<IEventTicketSection>,
      required: true,
    },
    valid: { type: Boolean },
  },
  setup() {
    const { original } = useSaveContext<any>();

    const inputs = computed(() => {
      const occurrenceTitle = match(original.value.occurrence_type, {
        [EventOccurrenceType.SESSION]: "Session",
        [EventOccurrenceType.TOUR]: "Tour",
      });

      return defineFormInputs({
        name: {
          name: "Name",
          rules: Rule.group([Rule.required(), Rule.title()]),
          type: "text",
          props: {
            placeholder: "e.g. General Admission",
          },
        },
        occurrence_id: {
          name: occurrenceTitle,
          component: OccurrenceSelect,
          tipInfo: `Restrict this section and its tickets to a ${occurrenceTitle}`,
          hidden:
            !original.value.occurrence_type ||
            original.value.occurrence_type == EventOccurrenceType.STANDARD,
        },
        capacity: {
          name: "Capacity",
          rules: Rule.group([
            Rule.required(),
            Rule.number.min(1),
            Rule.number.max(1000),
          ]),
          type: "number",
          props: {
            min: 1,
            max: 1000,
          },
        },
        disabled: {
          hideName: true,
          component: CheckBox,
          // class: 'mb-4',
          props: {
            title: "Disable tickets in this section",
          },
        },
      });
    });

    return {
      inputs,
    };
  },
  computed: {
    model: {
      set(value: IEventTicketSection) {
        this.$emit("update:modelValue", value);
      },
      get() {
        return this.modelValue;
      },
    },
    validModel: {
      set(value: boolean) {
        this.$emit("update:valid", value);
      },
      get() {
        return !!this.valid;
      },
    },
  },
});
</script>
