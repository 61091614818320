<template>
  <v-card class="pa-4">
    <div class="form-section-heading">Agenda</div>
    <SectionsContent id="event-agenda">
      <template #section-content="{ model, valid, remove }">
        <v-dynamic-form
          v-model="model.value"
          v-model:valid="valid.value"
          :inputs="inputs"
          class="pa-4"
        />
        <v-divider />
        <div class="px-4 py-2 highlight flex justify-end">
          <v-btn @click="remove" class="text-none" variant="text"
            >Remove agenda</v-btn
          >
        </div>
      </template>
    </SectionsContent>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { Rule } from "~/services/Rules";

export default defineComponent({
  setup() {
    const { data, getValid } = useSaveContext<any>();
    const valid = getValid("agenda");

    useSectionContent<any>({
      id: "event-agenda",
      identify: true,
      sortable: true,
      emptyIcon: "summarize",
      emptyDescription:
        "Add agenda to this event so that your attendees know what to expect.",
      items: {
        set(items) {
          data.value.agenda = items;
        },
        get() {
          return data.value.agenda || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: () => {
          const position = data.value.agenda?.length || 0;

          let from: string | undefined;

          // if (position) {
          //   const prev = sortedItems.value[position - 1]!

          //   if (prev.to) {
          //     from = prev.to
          //   } else if (prev.from) {
          //     const [h, m] = prev.from.split(':')
          //     const x = Number(m) + 15 // +15m
          //     from = formatMinutesNoUnit(Number(h) + x / 60)
          //   }
          // }

          return {
            name: "Item " + (position + 1),
            position,
          };
        },
      },
    });
  },
  data: () => ({
    inputs: defineFormInputs({
      name: {
        name: "Title",
        rules: Rule.group([Rule.required(), Rule.title()]),
        type: "text",
      },
      from: {
        name: "From",
        line: 2,
        component: VTimePicker,
        confirmEdit: true,
        props: {
          clearable: true,
          title: "Pick start time",
          targetProps: {
            format: "24hr",
            // min: props.prev?.to || props.prev?.from,
            // max: item.value.to,
          },
        },
      },
      to: {
        name: "To",
        line: 2,
        component: VTimePicker,
        confirmEdit: true,
        props: {
          clearable: true,
          title: "Pick start time",
          targetProps: {
            format: "24hr",
            // min: item.value.from,
          },
        },
      },
      description: {
        name: "Description",
        rules: Rule.short(),
        type: "textarea",
        props: {
          counter: 320,
          rows: 3,
        },
      },
    }),
  }),
});
</script>
