<template>
  <div class="flex gap-2">
    <div>
      <v-progress-circular
        :model-value="total.ratio"
        :max="100"
        :rotate="360"
        :size="120"
        :width="4"
        color="primary"
      >
        <div class="flex-center flex-col">
          <span class="text-7 font-semibold line-height-none"
            >{{ total.ratio }}%</span
          >
          <span class="caption">{{ total.quantity }}/{{ total.capacity }}</span>
        </div>
      </v-progress-circular>
    </div>

    <div class="relative flex flex-col gap-1">
      <div v-if="loading" class="w-150px md:w-200px">
        <v-skeleton-loader type="list-item-three-line" />
      </div>
      <div v-for="(dataset, i) in datasets" :key="i" class="flex-align gap-2">
        <div>
          <v-progress-circular
            :model-value="dataset.ratio"
            :max="100"
            :rotate="360"
            :size="35"
            :width="2"
            color="primary"
          >
            <span class="text-3">{{ dataset.ratio }}%</span>
          </v-progress-circular>
        </div>
        <div>
          <div class="heading-3">{{ dataset.ticket }}</div>
          <div class="caption">
            {{ dataset.quantity }}/{{ dataset.capacity }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { get, sum } from "lodash";
import { defineComponent, type PropType } from "vue";
import type { MetricResult } from "~/layers/admin/modules/metrics/runtime/types";

export default defineComponent({
  props: {
    storeKey: { type: String },
    data: { type: Object as PropType<Partial<MetricResult<string>>> },
    quantity: { type: [Number, String], default: 0 },
    capacity: { type: [Number, String], default: 1 },
    loading: { type: Boolean, default: false },
  },
  setup(props) {
    const radio = (x: number, y: number) => {
      return Math.round(y > 0 ? (x / y) * 100 : 0);
    };

    const datasets = computed(() => {
      const datasets = props.data?.datasets || [];

      return datasets.map((dataset) => {
        const quantity = get(dataset.data, Number(props.quantity), 0);
        const capacity = get(dataset.data, Number(props.capacity), 0);

        return {
          ticket: dataset.label,
          ratio: radio(quantity, capacity),
          quantity,
          capacity,
        };
      });
    });

    const total = computed(() => {
      const quantity = sum(datasets.value.map((dataset) => dataset.quantity));
      const capacity = sum(datasets.value.map((dataset) => dataset.capacity));

      return {
        ratio: radio(quantity, capacity),
        quantity,
        capacity,
      };
    });

    return {
      datasets,
      total,
    };
  },
});
</script>
