<template>
  <v-card class="pa-4">
    <div class="form-section-heading">Sections</div>
    <SectionsContent id="ticket-sections">
      <template #section-content="{ key, model, valid, remove }">
        <TicketSectionInput
          v-model="model.value"
          v-model:valid="valid.value"
          :content-key="key"
        />
        <v-divider />
        <div class="px-4 py-2 highlight flex justify-end">
          <v-btn @click="remove" class="text-none" variant="text">
            Remove section
          </v-btn>
        </div>
      </template>
    </SectionsContent>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { IEventTicketSection } from "~/models/interfaces/Event";
import TicketSectionInput from "./sections/TicketSectionInput.vue";

export default defineComponent({
  components: { TicketSectionInput },
  setup() {
    const { data, getValid } = useSaveContext<any>();
    const valid = getValid("sections");

    useSectionContent<IEventTicketSection>({
      id: "ticket-sections",
      sortable: true,
      addText: "Add section",
      emptyIcon: "select_all",
      emptyDescription: "Add sections to be able to add tickets",
      items: {
        set(sections) {
          data.value.sections = sections;
        },
        get() {
          return data.value.sections || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: () => {
          const position = data.value.sections?.length || 0;
          const name = position
            ? "Section " + (position + 1)
            : "General admission";
          return {
            name,
            capacity: 5,
            position,
            disabled: false,
            tickets: [],
          };
        },
      },
    });

    return {
      //
    };
  },
});
</script>
