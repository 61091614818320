<template>
  <SectionsContent :id="contentId" horizontal>
    <template #section-content="{ model, valid, remove }">
      <v-dynamic-form
        v-model="model.value"
        v-model:valid="valid.value"
        :inputs="inputs(model.value)"
        class="px-4 mt-4"
      />

      <DialogForm class="mx-4 mb-2" v-bind="moreOptions(model)" />

      <v-divider />

      <div class="px-4 py-2 flex justify-end">
        <v-btn @click="remove" class="text-none" variant="text"
          >Remove ticket</v-btn
        >
      </div>
    </template>
  </SectionsContent>
</template>

<script lang="ts">
import {
  BasicEditor,
  CheckBox,
  ChipSelect,
  ComplexPricing,
  DatetimePicker,
} from "#components";
import { defineComponent } from "vue";
import { EventTicketType } from "~/layers/admin/models";
import type { IEventTicket } from "~/models/interfaces/Event";
import { Rule } from "~/services/Rules";

export default defineComponent({
  props: {
    parentKey: { type: String, required: true },
    modelValue: { type: Array as PropType<IEventTicket[]> },
  },
  setup(props, ctx) {
    const contentId = `event-tickets:${props.parentKey}`;
    const { getValid } = useSaveContext<any>();
    const valid = getValid(["sections", props.parentKey]);
    const { currency } = useWorkspace();

    useSectionContent<IEventTicket>({
      id: contentId,
      sortable: true,
      addText: "Add ticket",
      emptyIcon: "receipt",
      emptyDescription: "Add tickets to be able to admit guests.",
      items: {
        set(tickets) {
          ctx.emit("update:modelValue", tickets);
        },
        get() {
          return props.modelValue || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: () => {
          return {
            name: "",
            type: EventTicketType.PAID,
            private: false,
            pickup_at_event: false,
            absorb_referral_fees: false,
            absorb_platform_fees: false,
          };
        },
      },
    });

    const inputs = (ticket: IEventTicket) => {
      const isPaid = ticket.type === EventTicketType.PAID;
      const comparisonPricing = !!ticket.enable_comparison_pricing && isPaid;

      return defineFormInputs({
        type: {
          name: "Type",
          rules: Rule.required(),
          component: ChipSelect,
          class: "mb-2",
          props: {
            items: Object.values(EventTicketType),
          },
        },
        name: {
          name: "Name",
          rules: Rule.group([Rule.required(), Rule.title()]),
          type: "text",
          props: {
            placeholder: "e.g. Adult Ticket",
          },
        },
        pricing: {
          name:
            (ticket.enable_comparison_pricing ? "Regular " : "") + "Pricing",
          component: ComplexPricing,
          hidden: !isPaid,
          rules: isPaid
            ? Rule.group([Rule.required(), Rule.pricing()])
            : undefined,
          class: "mb-4",
          props: {
            currency: currency.value?.symbol,
          },
        },
        enable_comparison_pricing: {
          hideName: true,
          component: CheckBox,
          hidden: !isPaid,
          class: "mb-4 mt-2",
          props: {
            title: "Discount Comparison Pricing",
            subtitle:
              "This pricing will be used to show discounts of regular prices. It should be less than relugar pricing.",
          },
        },
        comparison_pricing: {
          name: "Original Pricing",
          component: ComplexPricing,
          hidden: !comparisonPricing,
          rules: comparisonPricing
            ? Rule.group([Rule.required(), Rule.pricing()])
            : undefined,
          props: {
            currency: currency.value?.symbol,
          },
        },
      });
    };

    const moreOptions = (ticket: Ref<IEventTicket>) => {
      const isPaid = ticket.value.type === EventTicketType.PAID;
      const isFree = ticket.value.type === EventTicketType.FREE;
      const original: any = toRaw(ticket.value);

      const onSubmit = (data: any) => {
        Object.assign(ticket.value, data || {});
      };

      return defineDialogFormInput({
        onSubmit,
        title: "More options",
        subtitle: "Description, sales times, limits and more.",
        form: {
          title: "More options",
          doneText: "Save",
          inputsDefaults: original,
          inputs: defineFormInputs({
            description: {
              name: "Description",
              rules: Rule.short(),
              component: BasicEditor,
              class: "mb-3",
              props: {
                counter: 320,
                height: 100,
              },
            },
            // pickup_at_event: {
            //   hideName: true,
            //   component: CheckBox,
            //   props: applyPropDefaults({
            //     title: 'Pickup at event',
            //     // subtitle: '',
            //   }),
            // },
            sale_starts_at: {
              name: "Sale start date",
              line: 2,
              component: DatetimePicker,
              confirmEdit: true,
              props: {
                title: "Select sale start date",
                width: "auto",
                prependInnerIcon: "calendar_month",
                targetProps: {
                  hideHeader: true,
                },
                clearable: true,
              },
            },
            sale_ends_at: {
              name: "Sale end date",
              rules: ticket.value.sale_starts_at
                ? `after:${ticket.value.sale_starts_at}`
                : undefined,
              line: 2,
              component: DatetimePicker,
              confirmEdit: true,
              props: {
                title: "Select sale end date",
                width: "auto",
                prependInnerIcon: "calendar_month",
                targetProps: {
                  hideHeader: true,
                },
                clearable: true,
              },
            },
            available_quantity: {
              name: "Available quantity",
              rules: Rule.group([
                Rule.number.min(1),
                // Rule.number.max(availableCapacity),
              ]),
              type: "number",
              props: {
                min: 1,
                placeholder: "10",
                suffix: "available",
              },
            },
            per_order_limit: {
              name: "Per order limit",
              rules: Rule.group([
                Rule.number.min(1),
                Rule.number.max(ticket.value.available_quantity!),
              ]),
              type: "number",
              props: {
                min: 1,
                placeholder: "3",
              },
            },
            absorb_referral_fees: {
              hideName: true,
              component: CheckBox,
              hidden: !isPaid,
              class: "mb-4",
              props: {
                title: "Absorb referral fees",
                subtitle:
                  "By default, Orie's referral fees are paid by the attendee.",
              },
            },
            absorb_platform_fees: {
              hideName: true,
              component: CheckBox,
              hidden: isFree,
              class: "mb-4",
              props: {
                title: "Absorb platform fees",
                subtitle:
                  "By default, Orie's platform fees are paid by the attendee.",
              },
            },
            max_seats: {
              name: "Max seats",
              rules: Rule.group([Rule.number.min(1), Rule.number.max(10)]),
              type: "number",
              // hidden: !ticket.reserved_seating,
              class: "mb-2",
              props: {
                min: 1,
                max: 10,
                placeholder: "5",
                hint: "Sets the maximum seats a buyer can pick when purchasing this ticket. E.g. 10 for a family ticket.",
                persistentHint: true,
              },
            },
            purchase_note: {
              name: "Purchase note",
              rules: Rule.string.max(256),
              type: "textarea",
              // hidden: !ticket.reserved_seating,
              props: {
                placeholder: "E.g.: Max. of 2 adults and 3 children",
                hint: "Show buyers a note on this ticket. This could be a condition.",
                persistentHint: true,
              },
            },
          }),
        },
      });
    };

    return {
      contentId,
      inputs,
      moreOptions,
    };
  },
});
</script>
