<template>
  <v-list-item
    @click="open"
    :subtitle="subtitle"
    lines="two"
    class="rounded-lg px-2"
  >
    <template #title>
      <div class="text-primary">{{ title }}</div>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { FieldInputsWithoutKey } from "~/layers/ui/modules/VDynamicForm/runtime/types";
import type { ConfirmationInput } from "../private/confirm";

export default defineComponent({
  name: "DialogForm",
  props: {
    title: { type: String, required: true },
    subtitle: { type: String },
    form: {
      type: Object as PropType<
        ConfirmationInput & { inputs: FieldInputsWithoutKey }
      >,
      required: true,
    },
  },
  setup(props, ctx) {
    const { wrapConfirmInput } = useConfirm();

    const open = wrapConfirmInput(
      (data) => {
        ctx.emit("submit", data);
      },
      () => toRaw(props.form)
    );

    return {
      open,
    };
  },
});
</script>
