<template>
  <v-card class="pa-4">
    <div class="form-section-heading">General settings</div>
    <v-dynamic-form
      v-model="data"
      v-model:valid="valid"
      :inputs="inputs"
      :readonly="!canEdit"
    />
  </v-card>
</template>

<script lang="ts">
import { AgeRestrictionSelect, CountDownSelect } from "#components";
import { defineComponent } from "vue";
import { Rule } from "~/services/Rules";

export default defineComponent({
  setup() {
    const { data, canEdit, useValid } = useSaveContext<any>();
    const valid = useValid("advanced-fields");

    return {
      data,
      canEdit,
      valid,
    };
  },
  data: () => ({
    inputs: defineFormInputs({
      age_restriction: {
        name: "Age restriction",
        component: AgeRestrictionSelect,
      },
      count_down: {
        name: "Count down",
        component: CountDownSelect,
      },
      refund_policy: {
        name: "Refund policy",
        rules: Rule.short(),
        type: "textarea",
        props: {
          counter: 320,
          rows: 3,
        },
      },
    }),
  }),
});
</script>
