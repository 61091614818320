<template>
  <div>
    <v-dynamic-form
      v-model="dataModel"
      v-model:valid="valid"
      :inputs="inputs"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Rule } from "~/services/Rules";
import type Event from "~/models/dobby/Event";
import VPhoneInput from "~/layers/ui/modules/VDynamicForm/runtime/components/VPhoneInput";
import { CheckBox } from "#components";
import { EventSeatPicker } from "~/components/pages/event-seating/pick-seats";
import type { IEventOccurrence } from "~/models/interfaces/Event";
import { sum } from "lodash";

export default defineComponent({
  props: {
    modelValue: { type: Object as PropType<any>, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const valid = ref(false);
    const dataModel = ref<any>();
    const { original } = useSaveContext<Event>();

    watchEffect(() => {
      if (valid.value) {
        ctx.emit("update:modelValue", dataModel.value);
      }
    });

    const hasMaps = computed(() => {
      const occurrences = original.value.occurrences || [];
      return someTrue(mapBy(occurrences, "reserved_seating"));
    });

    const getTickets = () => {
      let sections = toRaw(original.value.sections);
      const occurrence = dataModel.value?.occurrence;

      if (occurrence && original.value.occurrences.length > 1) {
        sections = sections.filter((s) => s.occurrence_id == occurrence);
      }

      return sections.map((section) => section.tickets).flat();
    };

    const getOccurrence = () => {
      if (original.value.occurrences.length == 1) {
        return original.value.occurrences[0];
      }
      return original.value.occurrences.find(
        (s) => s.id == dataModel.value?.occurrence
      );
    };

    const getAvailableSeats = (occurrence?: IEventOccurrence) => {
      if (occurrence) {
        // allow using spetial seats
        const extraSeats: string[] = occurrence.special_seats.filter(
          (seat) =>
            !occurrence.used_seats.includes(seat) &&
            !occurrence.available_seats.includes(seat)
        );

        return [...occurrence.available_seats, ...extraSeats];
      }
    };

    const inputs = computed(() => {
      const tickets = getTickets();
      const occurrence = getOccurrence();
      const availableSeats = getAvailableSeats(occurrence);

      const maxSeats = execFn(() => {
        const selectedTicket = dataModel.value?.ticket;
        const ticket = tickets.find((item) => item?.gid == selectedTicket);
        return ticket?.max_seats || 1;
      });

      return defineFormInputs({
        occurrence: {
          name: "Occurrence",
          type: "select",
          rules: Rule.required(),
          hidden: original.value.occurrences.length == 1,
          props: {
            items: original.value.occurrences,
            itemTitle: "name",
            itemValue: "id",
          },
        },
        // type: {
        //   name: "Type",
        //   rules: Rule.required(),
        //   type: "select",
        //   props: {
        //     items: [
        //       {
        //         title: "Paid",
        //         value: "paid",
        //         subtitle: "The guest's name. Also used when emailing the guest",
        //       },
        //       {
        //         title: "Free",
        //         value: "free",
        //         subtitle: "The guest's name. Also used when emailing the guest",
        //       },
        //     ],
        //     persistentHint: true,
        //   },
        // },
        name: {
          name: "Name",
          rules: Rule.group([Rule.required(), Rule.title()]),
          type: "text",
          props: {
            hint: "The guest's name. Also used when emailing the guest",
            persistentHint: true,
          },
        },
        email: {
          name: "Email",
          rules: Rule.group([Rule.required(), Rule.email()]),
          type: "text",
          line: 3,
        },
        phone: {
          name: "Phone",
          component: VPhoneInput,
          line: 3,
        },
        send_email: {
          hideName: true,
          component: CheckBox,
          class: "mb-2",
          props: {
            title: "Send tickets to the provided email",
          },
        },
        ticket: {
          name: "Ticket",
          type: "select",
          rules: Rule.required(),
          props: {
            items: tickets,
            itemTitle: "name",
            itemValue: "gid",
          },
        },
        seats: {
          name: "Seats",
          rules: Rule.required(),
          component: EventSeatPicker,
          hidden: !hasMaps.value,
          disabled: !props.modelValue?.ticket,
          props: {
            eventMap: occurrence?.event_map,
            availableSeats,
            maxSeats,
          },
        },
      });
    });

    return {
      dataModel,
      inputs,
      valid,
    };
  },
});
</script>
