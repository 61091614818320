<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <GeneralFields />
        <MediaFields class="mt-4" />
      </v-col>
      <v-col cols="12" md="4">
        <EventSummary />
        <ScheduleFields class="mt-4" />
        <SeoFields class="mt-4" resource-name="event" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GeneralFields from "./sections/GeneralFields.vue";
import MediaFields from "./sections/MediaFields.vue";
import EventSummary from "./sections/EventSummary.vue";
import ScheduleFields from "./sections/ScheduleFields.vue";
import SeoFields from "../shared/sections/SeoFields.vue";

export default defineComponent({
  components: {
    GeneralFields,
    MediaFields,
    EventSummary,
    ScheduleFields,
    SeoFields,
  },
});
</script>
