<template>
  <v-card>
    <div class="pa-4">
      <div class="form-section-heading">Media</div>
      <SaveContextMedia name="image" aspect-ratio="2" allow-link />
    </div>
    <v-divider />
    <v-dynamic-form
      v-model="data"
      v-model:valid="valid"
      :inputs="videoInputs"
      :readonly="!canEdit"
      :size="10e6"
      class="pa-4"
    />
    <v-divider />
    <div class="pa-4">
      <SaveContextMedia
        name="gallery"
        :size="10e6"
        multiple
        allow-library
        allow-link
      >
        <template #top>
          <div class="text-subtitle-2">Gallery</div>
        </template>
      </SaveContextMedia>
    </div>

    <MediaEditor />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Rule } from "~/services/Rules";

export default defineComponent({
  setup() {
    const { getValid, data, canEdit } = useSaveContext<any>();

    const valid = getValid("media");

    return {
      data,
      canEdit,
      valid,
    };
  },
  data: () => ({
    videoInputs: defineFormInputs({
      video_url: {
        name: "Video URL",
        rules: Rule.url(),
        type: "text",
        props: {
          prependInnerIcon: "play_circle_outline",
          placeholder: "Youtube or Vimeo URL",
        },
      },
    }),
  }),
});
</script>
