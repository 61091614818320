<template>
  <div>
    <ActionDialog
      ref="dialog"
      @opened="getIntent"
      :loading="downloading"
      :action="doDownload"
      :inactive="!valid"
      title="Download event details"
      done-text="Download"
      max-height="500"
      width="550"
      invert-highlight
      pad
    >
      <v-select
        v-if="occurrences.length > 1"
        v-model="input.occurrence"
        :items="occurrences"
        label="Occurrence"
        variant="outlined"
        rounded="md"
      />

      <WrapCheckBoxInput
        v-model="input.attendees"
        title="Attendees"
        subtitle="Include event attendees in the download."
        class="mb-2"
      >
        <div class="heading-2">Columns</div>
        <v-progress-circular
          v-if="loadingAttendeeIntent"
          indeterminate
          width="1"
          size="20"
          class="mb-2"
        />
        <template v-else>
          <v-combobox
            v-model="attendeeColumns"
            :items="attendeeExportColumns"
            item-title="name"
            item-value="key"
            density="compact"
            chips
            closable-chips
            multiple
            hide-details
          />
          <v-checkbox
            v-model="selectAllAttendeeColumns"
            label="Select all"
            hide-details
          />
        </template>
      </WrapCheckBoxInput>

      <WrapCheckBoxInput
        v-model="input.map.include"
        :disabled="!hasMap"
        title="Map"
        subtitle="Include event map in the download."
        class="mb-2"
      >
        <div>
          <v-select
            v-model="input.map.pageSize"
            :items="pageSizes"
            label="Page size"
            variant="outlined"
            rounded="md"
          />
          <v-select
            v-model="input.map.resolution"
            :items="resolutions"
            label="Resolution"
            variant="outlined"
            rounded="md"
          />
          <v-checkbox
            v-model="input.map.showBooked"
            label="Show booked seats"
            hide-details
          />
          <v-checkbox
            v-model="input.map.showReserved"
            label="Show reserved seats"
            hide-details
          />
        </div>
      </WrapCheckBoxInput>

      <CheckBox
        v-model="input.agenda"
        :disabled="!hasAgenda"
        title="Event Agenda"
        subtitle="Include event agenda in the download."
      />
    </ActionDialog>
    <v-dialog v-model="mapOpen">
      <div id="map"></div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WrapCheckBoxInput from "@/components/forms/shared/booking/WrapCheckBoxInput.vue";
import type Event from "~/models/dobby/Event";
import {
  DateRange,
  useExport,
  type ExportColumn,
} from "~/layers/admin/private/exporter";
import {
  createExporter,
  type EventMapExportFormat,
} from "../event-seating/pick-seats/exporter";

export default defineComponent({
  components: { WrapCheckBoxInput },
  setup() {
    const downloading = ref(false);
    const dialog = ref<any>();
    const mapOpen = ref(false);
    const input = ref({
      occurrence: "",
      attendees: true,
      map: {
        include: false,
        pageSize: "a4" as EventMapExportFormat,
        resolution: 72,
        showBooked: true,
        showReserved: false,
      },
      agenda: false,
    });

    const { handle } = useNamespacedEvent("event");

    const getOccurrence = () => {
      const items = model.value?.occurrences || [];
      return items.find((item) => item.id == input.value.occurrence);
    };

    const { model } = useSaveContext<Event>();
    const export$1 = useExport("event_attendees", {
      defaultDateRange: DateRange.ALL,
    });
    const export$2 = createExporter();

    const attendeeColumns = computed({
      set(value: ExportColumn[]) {
        export$1.input.value.columns = value;
      },
      get() {
        return export$1.columns.value;
      },
    });

    const valid = computed(() => {
      return !!input.value.occurrence && export$1.valid.value;
    });

    const hasMap = computed(() => {
      const occurrence = getOccurrence();
      return !!occurrence?.reserved_seating && !!occurrence?.event_map;
    });

    const hasAgenda = computed(() => {
      // return !!model.value?.agenda?.length;
      return false;
    });

    const occurrences = computed(() => {
      const items = model.value?.occurrences || [];
      return items.map((item) => ({
        title: item.name,
        value: item.id,
      }));
    });

    handle("download-details", () => {
      dialog.value?.open();

      if (!input.value.occurrence && occurrences.value.length == 1) {
        input.value.occurrence = occurrences.value[0].value;
      }
    });

    const doDownload = async () => {
      downloading.value = true;

      const p: Promise<any>[] = [];

      // TODO: or occurrence
      export$1.input.value.args = { event: model.value?.handle! };

      if (input.value.attendees) {
        p.push(export$1.doExport());
      }
      if (input.value.map) {
        const occurrence = getOccurrence();
        p.push(
          export$2.doExport({
            map: occurrence!.event_map!,
            format: input.value.map.pageSize,
            resolution: input.value.map.resolution,
          })
        );
      }
      if (input.value.agenda) {
        // print event agenda
      }

      await Promise.all(p);

      downloading.value = false;
    };

    return {
      downloading,
      dialog,
      mapOpen,
      input,
      valid,
      hasMap,
      hasAgenda,
      occurrences,
      attendeeColumns,
      attendeeExportColumns: export$1.columns,
      selectAllAttendeeColumns: export$1.selectAllColumns,
      loadingAttendeeIntent: export$1.loadingIntent,
      getIntent: export$1.getIntent,
      doDownload,
    };
  },
  data: () => ({
    pageSizes: [
      { title: "A0 (slow)", value: "a0" },
      { title: "A1", value: "a1" },
      { title: "A2", value: "a2" },
      { title: "A3", value: "a3" },
      { title: "A4", value: "a4" },
      { title: "A5 (fast)", value: "a5" },
    ],
    resolutions: [
      { title: "72 dpi (fast)", value: 72 },
      { title: "150 dpi", value: 150 },
      { title: "300 dpi (slow)", value: 300 },
    ],
  }),
});
</script>
