import { createComponent } from "../private/editor/base";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Heading from "@tiptap/extension-heading";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";

export default createComponent({
  name: "BasicEditor",
  marks: [
    {
      group: 1,
      ext: Bold,
      title: "Bold",
      shortcut: "Ctrl+B",
      active: (editor) => editor.isActive("bold"),
      handle: (editor) => editor.chain().focus().toggleBold().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleBold().run(),
      icon: "format_bold",
    },
    {
      group: 1,
      ext: Italic,
      title: "Italic",
      shortcut: "Ctrl+I",
      active: (editor) => editor.isActive("italic"),
      handle: (editor) => editor.chain().focus().toggleItalic().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleItalic().run(),
      icon: "format_italic",
    },
    {
      group: 1,
      ext: Strike,
      title: "Strike",
      shortcut: "Ctrl+S",
      active: (editor) => editor.isActive("strike"),
      handle: (editor) => editor.chain().focus().toggleStrike().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleStrike().run(),
      icon: "strikethrough_s",
    },
    {
      group: 1,
      ext: Underline,
      title: "Underline",
      shortcut: "Ctrl+U",
      active: (editor) => editor.isActive("underline"),
      handle: (editor) => editor.chain().focus().toggleUnderline().run(),
      disabled: (editor) =>
        !editor.can().chain().focus().toggleUnderline().run(),
      icon: "format_underlined",
    },
    {
      group: 2,
      ext: Heading,
      title: "Level 1 header",
      active: (editor) => editor.isActive("heading", { level: 1 }),
      handle: (editor) =>
        editor.chain().focus().toggleHeading({ level: 1 }).run(),
      textIcon: "H1",
    },
    {
      group: 2,
      title: "Level 2 header",
      active: (editor) => editor.isActive("heading", { level: 2 }),
      handle: (editor) =>
        editor.chain().focus().toggleHeading({ level: 2 }).run(),
      textIcon: "H2",
    },
    {
      group: 2,
      ext: BulletList,
      title: "Bullet list",
      active: (editor) => editor.isActive("bulletList"),
      handle: (editor) => editor.chain().focus().toggleBulletList().run(),
      icon: "format_list_bulleted",
    },
    {
      group: 2,
      ext: OrderedList,
      title: "Ordered list",
      active: (editor) => editor.isActive("orderedList"),
      handle: (editor) => editor.chain().focus().toggleOrderedList().run(),
      icon: "format_list_numbered",
    },
  ],
});
