<template>
  <div class="flex-align gap2 justify-end mb-4">
    <v-btn @click="createManualTicket" elevation="1">
      <v-icon class="mr-1" size="20">add</v-icon>
      Add a manual ticket
    </v-btn>
    <v-btn @click="print" elevation="1">
      <v-icon class="mr-1" size="20">print</v-icon>
      Print attendees
    </v-btn>
  </div>

  <DataTable v-bind="ticketsTable" :loading="loading" :items="tickets">
    <template #before>
      <div class="form-section-heading px-4 mt-2">Attendees</div>
      <v-divider />
    </template>
  </DataTable>

  <DataTable
    class="mt-4"
    v-bind="discountsTable"
    :loading="loading"
    :items="discounts"
  >
    <template #before>
      <div class="flex-align justify-between px-4 my-2">
        <div class="form-section-heading">Discounts</div>
        <AppActions namespace="event-discounts" :actions="discountsActions" />
      </div>
      <v-divider />
    </template>
  </DataTable>

  <EditEventTicket />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { first, get, kebabCase, omit } from "lodash";
import { jsPDF } from "jspdf";
import type { autoTable, RowInput } from "jspdf-autotable";
import "jspdf-autotable";
import type Event from "~/models/dobby/Event";
import { Rule } from "~/services/Rules";
import type { IEventDiscount, IEventTicket } from "~/models/interfaces/Event";
import { EventDiscount } from "~/models/dobby/EventDiscount";
import ManualTicketInput from "./sections/ManualTicketInput.vue";
import EditEventTicket from "./sections/EditEventTicket.vue";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useToast } from "vue-toastification";
import type { TicketItem } from "./sections/types";
import { TextCopy } from "#components";

export default defineComponent({
  components: { EditEventTicket },
  setup() {
    const { handle: handleDiscounts } = useNamespacedEvent("event-discounts");
    const { original, data, loading, refetch } = useSaveContext<Event>();
    const { wrapConfirmInput } = useConfirm();
    const { wrapLoading } = useLoading();
    const cachedDiscounts = shallowRef<IEventDiscount[]>([]);
    const toast = useToast();

    const tickets = computed(() => {
      const bookings = data.value.bookings || [];
      const items = bookings.map((booking): TicketItem | void => {
        const ticket = first(booking.tickets);
        if (ticket) {
          return {
            id: ticket.id,
            created_at: ticket.created_at,
            name: ticket.name,
            attendees: execFn(() => {
              const len = booking.tickets?.length || 0;
              if (len > 1) {
                return mapBy(booking.tickets, "name").join(", ");
              }
            }),
            ticket: ticket.eventTicket.name,
            code: ticket.code,
            email: ticket.email,
            displaySeats: ticket.displaySeats,
            ticketsCount: booking.tickets?.length || 0,
            order: booking.order,
            booking,
          };
        }
      });

      return items.filter((v) => !!v) as TicketItem[];
    });

    const discounts = computed(() => {
      const o = toRaw(original.value.discounts || []);
      const c = toRaw(cachedDiscounts.value);
      return [...o, ...c];
    });

    const createManualTicketMutation = useMutation(gql`
      mutation ($data: EventManualTicketCreateInput!) {
        wsCreateManualTicket(data: $data) {
          code
        }
      }
    `);

    const getTickets = () => {
      return toRaw(original.value.sections)
        .map((section) => section.tickets)
        .flat();
    };

    const createDiscount = wrapConfirmInput(
      wrapLoading(async (input) => {
        const inputTickets: IEventTicket[] = input.tickets;

        let occurrence: string;
        if (original.value.occurrences.length > 1) {
          const section = original.value.sections.find((x) => {
            if (x.tickets) {
              const tickets = x.tickets.map((t) => t.id);
              return !!inputTickets.find((ticket) =>
                tickets.includes(ticket.id)
              );
            }
          });
          occurrence = section?.occurrence_id!;
        } else {
          occurrence = get(original.value.occurrences, "[0].id");
        }

        const model = await EventDiscount.create({
          ...input,
          event_occurrence: occurrence,
          tickets: inputTickets.map((ticket) => ({
            id: ticket.gid,
            quantity: 1,
          })),
        });

        toast.success("Discount created");

        cachedDiscounts.value = [
          ...cachedDiscounts.value,
          model.$toJson() as any,
        ];

        refetch().then(() => {
          cachedDiscounts.value = [];
        });
      }),
      () => {
        const tickets = getTickets();
        // const reservedSeating = execFn(() => {
        //   const occurrences = original.value.occurrences || [];
        //   return someTrue(mapBy(occurrences, "reserved_seating"));
        // });

        return {
          title: "Add a new discount",
          doneText: "Save",
          inputs: defineFormInputs({
            name: {
              name: "Name",
              rules: Rule.group([Rule.required(), Rule.title()]),
              type: "text",
              props: {
                hint: "The guest's name. Also used when emailing the guest",
                persistentHint: true,
              },
            },
            email: {
              name: "Email",
              rules: Rule.group([Rule.required(), Rule.email()]),
              type: "text",
            },
            // send_email: {
            //   hideName: true,
            //   component: CheckBox,
            //   props: {
            //     title: "Send magic link to the provided email",
            //     class: "mb-2",
            //   },
            // },
            // send_text: {
            //   hideName: true,
            //   component: CheckBox,
            //   props: {
            //     title: "Text magic link to the provided phone",
            //     class: "mb-2",
            //   },
            // },
            tickets: {
              name: "Tickets",
              type: "select",
              rules: Rule.required(),
              props: {
                returnObject: true,
                multiple: true,
                items: tickets,
                itemTitle: "name",
                itemValue: "id",
              },
            },
            // allow_reserved_seats: {
            //   hideName: true,
            //   component: CheckBox,
            //   hidden: !reservedSeating,
            //   props: {
            //     title: "Allow reserved seats",
            //     subtitle:
            //       "Allow the guest to pick reserved seats with this discount.",
            //     class: "mb-2",
            //   },
            // },
          }),
          inputsDefaults: {
            tickets: [],
            allow_reserved_seats: false,
          },
        };
      }
    );

    const createManualTicket = wrapConfirmInput(
      wrapLoading(async ({ input }) => {
        const data = omit(Object.assign({}, input), ["occurrence"]);
        createManualTicketMutation
          .mutate({ data })
          .then(() => {
            toast.success("Ticket created");
            refetch();
          })
          .catch(() => {
            toast.error("Failed to create manual ticket");
          });
      }),
      () => {
        return {
          title: "Create manual ticket",
          doneText: "Save",
          width: "550px",
          inputs: defineFormInputs({
            input: {
              hideName: true,
              component: ManualTicketInput,
              rules: Rule.required(),
            },
          }),
          inputsDefaults: {
            input: {},
          },
        };
      }
    );

    const print = () => {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      const heading = `${original.value.name} Attendees`;
      doc.setFontSize(16).text(heading, 0.5, 1.0);
      doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      (doc as jsPDF & { autoTable: autoTable }).autoTable({
        // TODO: set header color to (255, 165, 0)
        columns: [
          { title: "Name", dataKey: "name" },
          { title: "Ref", dataKey: "code" },
          { title: "Seats", dataKey: "displaySeats" },
          { title: "Email", dataKey: "email" },
        ],
        body: tickets.value.sort((a, b) =>
          a.name.localeCompare(b.name)
        ) as RowInput[],
        margin: { left: 0.5, top: 1.25 },
      });

      // doc
      //   .setFont("helvetica")
      //   .setFontSize(12)
      //   .text(this.moreText, 0.5, 3.5, { align: "left", maxWidth: "7.5" });

      const filename = `${kebabCase(heading)}.pdf`;

      doc
        .setFont("times")
        .setFontSize(11)
        // .setFontStyle("italic")
        .setTextColor(100, 100, 100)
        .text(
          "Generated " + new Date(),
          0.5,
          doc.internal.pageSize.height - 0.5
        )
        .save(filename);

      toast.success(`Attendees printed (${filename})`);
    };

    handleDiscounts("add:discount", createDiscount);

    return {
      original,
      tickets,
      discounts,
      loading,
      createManualTicket,
      print,
    };
  },
  data: () => ({
    ticketsTable: defineTable({
      showSelect: true,
      searchable: true,
      configurable: true,
      applyFilters: true,
      actionsNamespace: "event-attendees",

      emptyIcon: "heroicons:ticket",
      emptyTitle: "Ticket bookings will show here",
      emptyDescription:
        "This is where you’ll manage your event attendees. You can create tickets to add or invite special guests to this event.",

      views: [{ id: "all", title: "All" }],

      filters: {
        created_at: "date",
        order: "float",
      },

      columns: [
        { title: "Date", sortable: true, key: "created_at", type: "date" },
        { title: "Name", sortable: true, key: "name" },
        { title: "Ref.", sortable: true, key: "code", type: "title" },
        { title: "Ticket", sortable: true, key: "ticket" },
        { title: "Email", sortable: true, key: "email", type: "email" },
        { title: "Seats", sortable: false, key: "displaySeats" },
        { title: "Tickets", sortable: true, key: "ticketsCount" },
        { title: "Order", sortable: false, key: "order", type: "resource" },
      ],
    }),
    discountsTable: defineTable({
      actionsNamespace: "event-discounts",
      searchable: true,
      applyFilters: true,

      emptyIcon: "streamline:discount-percent-badge",
      emptyTitle: "Special discounts",
      emptyDescription:
        "You can use discounts to offer special tickets to your attendees.",

      views: [
        { id: "all", title: "All" },
        {
          id: "used",
          title: "Used",
          filters: tableFilter({ used: true }),
        },
        {
          id: "unused",
          title: "Unused",
          filters: tableFilter({ used: false }),
        },
      ],

      columns: [
        { title: "Name", sortable: true, key: "name" },
        {
          title: "Link",
          sortable: true,
          key: "web_url",
          component: dataTableComponent(TextCopy, {
            usePropKey: "text",
            useItemValue: true,
            props: {
              class: "md:max-w-350px",
            },
          }),
        },
        { title: "Email", sortable: true, key: "email", type: "email" },
        { title: "Used", sortable: false, key: "used", type: "boolean" },
        { title: "Tickets", sortable: false, key: "tickets", type: "count" },
      ],
    }),
    discountsActions: defineActions([
      {
        title: "Add discount",
        key: "add:discount",
        icon: "heroicons:plus",
      },
    ]),
  }),
});
</script>
