<template>
  <div v-if="hasMaps" class="flex-align justify-end">
    <v-btn @click="show" elevation="1">
      <v-icon class="mr-1" size="20">map</v-icon>
      Event map
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { EventOccurrence } from "~/models/dobby/Event";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { original } = useSaveContext<any>();

    const hasMaps = computed(() => {
      const occurrences: EventOccurrence[] = original.value.occurrences || [];
      return someTrue(mapBy(occurrences, "reserved_seating"));
    });

    const show = () => {
      const occurrences: EventOccurrence[] = original.value.occurrences || [];
      if (!occurrences.length) {
        return;
      }
      if (occurrences.length == 1) {
        router.push({
          name: "events-handle-seating-occurrence",
          params: {
            handle: String(route.params.handle),
            occurrence: occurrences[0].id,
          },
        });
      }

      // select one
    };

    return {
      hasMaps,
      show,
    };
  },
});
</script>
