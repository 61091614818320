<template>
  <v-row>
    <v-col cols="12" md="10">
      <PublicationFields />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PublicationFields from "./sections/PublicationFields.vue";

export default defineComponent({
  components: { PublicationFields },
});
</script>
