<template>
  <div>
    <div class="mt-4 flex gap-2">
      <MetricsPeriodPicker v-model:period="period">
        <template #activator="{ props, display }">
          <v-btn v-bind="props" class="text-none" elevation="1">
            <Icon name="heroicons:calendar-days-20-solid" />
            <span class="ml-1">{{ display }}</span>
          </v-btn>
        </template>
      </MetricsPeriodPicker>
    </div>

    <v-card class="mt-4">
      <div class="h-full grid grid-cols-24 divide-x">
        <div class="col-span-24 md:col-span-14 pa-4">
          <div class="heading-2">Sales</div>
          <div class="mt-2 flex justify-between">
            <div>
              <MetricTrendValue
                class="text-10 line-height-none"
                source="netSales"
                tag="div"
                with-unit
                format-price
              />
              <div class="caption">Net sales</div>
            </div>
            <div class="opacity-70 text-end md:text-start">
              <MetricValue
                class="text-10 line-height-none"
                source="totalSales"
                tag="div"
              />
              <div class="caption">Total sales</div>
            </div>
          </div>
          <MetricsChart class="mt-2" source="netSales" width="100%" />
        </div>
        <div class="col-span-24 md:col-span-10 border-l-[1px_solid_grey]">
          <v-card class="py-2 px-4 ma-2" variant="flat" rounded="lg" border>
            <div class="heading-2">Total tickets sold</div>
            <ProgressSummary
              class="mt-2"
              :data="ticketsSold"
              :loading="loading"
              quantity="1"
              capacity="2"
            />
          </v-card>
          <v-card class="py-2 px-4 ma-2" variant="flat" rounded="lg" border>
            <div class="heading-2">Total checked in</div>
            <ProgressSummary
              class="mt-2"
              :data="ticketsSold"
              :loading="loading"
              quantity="0"
              capacity="1"
            />
          </v-card>
        </div>
      </div>
    </v-card>

    <v-row class="mt-2">
      <v-col cols="12" md="8">
        <v-card class="pa-4">
          <div class="heading-2">Engagement</div>
          <div class="mt-2 flex gap-4">
            <div class="flex flex-col">
              <div class="caption">Average session</div>
              <div class="text-5.4 font-semibold">{{ avgSession }}</div>
            </div>
            <div class="flex flex-col">
              <div class="caption">New users</div>
              <div class="text-5.4 font-semibold">{{ newUsers }}</div>
            </div>
            <div class="flex flex-col">
              <div class="caption">CTR</div>
              <div class="text-5.4 font-semibold">—</div>
            </div>
            <!-- <div class="flex flex-col">
              <div class="caption">Bounce rate</div>
              <div class="text-5.4 font-semibold">—</div>
            </div> -->
          </div>
          <div class="mt-4">
            <div class="caption">Page views</div>
            <MetricsChart source="views" width="100%" />
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="pa-4">
          <div class="heading-2">Marketing</div>
          <div class="h-250px flex">
            <div class="text-center m-auto">
              <!-- source of traffic, conversion rates by source, promocodes used -->
              There was no data found for this report.
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProgressSummary from "~/components/analytics/elements/ProgressSummary.vue";
import { MetricsDataSourceType } from "~/layers/admin/modules/metrics/runtime/types";
import type {
  MetricsControls,
  MetricsDataSource,
  MetricsDataSource_Db,
} from "~/layers/admin/modules/metrics/runtime/types";
import Event from "~/models/dobby/Event";

export default defineComponent({
  components: { ProgressSummary },
  setup() {
    const period = ref<Date[]>();
    const { original } = useSaveContext<Event>();

    const eventScope = {
      args: {
        event: original.value.handle,
        // object: "event",
        // handle: original.value.handle,
      },
    };

    const sources = computed((): MetricsDataSource<string>[] => {
      const s: MetricsDataSource_Db<string>[] = [
        {
          ...eventScope,
          source: MetricsDataSourceType.Db,
          name: "orders",
          handle: "netSales",
          type: "trend",
          // aggregate: "avg",
        },
        {
          ...eventScope,
          source: MetricsDataSourceType.Db,
          name: "orders",
          handle: "totalSales",
          type: "value",
          // aggregate: "count",
        },
        {
          ...eventScope,
          source: MetricsDataSourceType.Db,
          name: "event_tickets",
          handle: "ticketsSold",
        },
        {
          ...eventScope,
          source: MetricsDataSourceType.Db,
          name: "page_views",
          handle: "views",
        },
        {
          ...eventScope,
          source: MetricsDataSourceType.Db,
          name: "page_sessions",
          handle: "avgSession",
          aggregate: "avg",
          type: "value",
        },
      ];

      return s;
    });

    const controls = computed((): MetricsControls => {
      if (period.value?.length == 1) {
        return {
          since: formatToDatetime(period.value[0]),
        };
      } else if (period.value?.length == 2) {
        return {
          since: formatToDatetime(period.value[0]),
          until: formatToDatetime(period.value[1]),
        };
      }

      return {};
    });

    const handle = computed(() => original.value.handle);

    const { loading, getResult, getters } = provideMetrics(sources, controls);

    const ticketsSold = computed(() => {
      return getResult("ticketsSold");
    });

    const avgSession = computed(() => {
      const { value } = getters.value("avgSession", { default: null });
      return value.value ? `${value.value}m` : "—";
    });

    const newUsers = computed(() => {
      return 0;
    });

    return {
      period,
      handle,
      loading,
      ticketsSold,
      avgSession,
      newUsers,
    };
  },
});
</script>
