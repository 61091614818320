<template>
  <v-card :loading="loading">
    <div class="pa-4">
      <div class="form-section-heading">Summary</div>
      <v-card class="pa-0" height="50" width="50">
        <div class="bg-red text-center caption min-h-20px">
          {{ startsAtFormatted }}
        </div>
        <div class="py-1 text-center">
          {{ date }}
        </div>
      </v-card>
      <div class="mt-2 text-red">{{ startsAt || "Today" }}</div>
      <div
        v-if="data.type && data.type.name"
        class="caption text-blue-800 font-semibold"
      >
        {{ data.type.name }}
      </div>
      <div class="py-0">
        <span v-if="data.name" v-text="data.name"></span>
        <span v-else class="faint-text">Event Name</span>
      </div>
      <div class="caption">
        <span v-if="model && location">{{ location }}</span>
        <span v-else class="faint-text">Location</span>
      </div>
    </div>
    <v-divider class="mx-4" />
    <v-list>
      <v-card-subtitle class="py-0">Details</v-card-subtitle>
      <v-list-item
        v-if="model && model.venue && model.venue.address_line"
        density="compact"
      >
        <template #prepend>
          <v-icon size="20">place</v-icon>
        </template>
        <v-list-item-content>{{
          model.venue.address_line
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item density="compact">
        <template #prepend>
          <v-icon size="20">public</v-icon>
        </template>
        <v-list-item-content>{{
          data.private ? "Private" : "Public"
        }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import dayjs from "dayjs";
import { first } from "lodash";
import type Event from "~/models/dobby/Event";

export default defineComponent({
  setup() {
    const { loading, data, model } = useSaveContext<Event>();

    const startsAt = computed(() => {
      const occurrence = first(data.value.occurrences);
      if (occurrence?.starts_at) {
        const d = dayjs(occurrence.starts_at);
        return markRaw(d);
      }
    });
    const startsAtFormatted = computed(() =>
      startsAt.value ? startsAt.value.format("ddd") : "—"
    );
    const date = computed(() => (startsAt.value ? startsAt.value.date() : 0));

    const location = computed(() => {
      const occurrences = data.value.occurrences || [];
      return occurrences
        .map(
          (occur) =>
            occur.bookingLocation?.full_title ||
            eventOccurrenceLocationTypeName(occur.bookingLocation?.type!)
        )
        .join(", ");
    });

    return {
      loading,
      data,
      startsAt,
      startsAtFormatted,
      date,
      model,
      location,
    };
  },
});
</script>

<style scoped>
.faint-text {
  color: rgba(0, 0, 0, 0.3);
}
</style>
