<template>
  <v-alert type="info" rounded="lg">
    <template #prepend>
      <v-avatar class="text-5 bg-white/10! text-white" size="40">
        <Icon name="ant-design:carry-out-twotone" />
      </v-avatar>
    </template>

    <div class="flex flex-col md:flex-row gap-2">
      <div>
        <div class="heading-2">Looks like we're live</div>
        Open Scan-to-Admit to check in and manage the attendees for this event.
      </div>
      <div class="md:flex md:justify-end flex-grow">
        <v-btn @click="sta" color="white" variant="elevated" elevation="1">
          Scan to admit
        </v-btn>
      </div>
    </div>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const { dispatch } = useNamespacedEvent("event");

    const sta = () => {
      dispatch("sta");
    };

    return {
      sta,
    };
  },
});
</script>
