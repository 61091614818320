<template>
  <EventMapAction class="mb-4" />

  <v-row>
    <v-col cols="12" :md="isMultiple ? 12 : 8">
      <v-card class="pa-4">
        <div v-if="title" class="form-section-heading">{{ title }}</div>
        <OccurrencesInput />
      </v-card>
    </v-col>
    <v-col cols="12" :md="isMultiple ? 8 : 4">
      <TimingOptionsFields />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EventMapAction from "./sections/EventMapAction.vue";
import OccurrencesInput from "./sections/OccurrencesInput.vue";
import TimingOptionsFields from "./sections/TimingOptionsFields.vue";
import { EventOccurrenceType } from "~/layers/admin/models";

export default defineComponent({
  components: {
    EventMapAction,
    OccurrencesInput,
    TimingOptionsFields,
  },
  setup() {
    const { data } = useSaveContext<any>();

    const title = computed(() => {
      return match(data.value.occurrence_type, {
        [EventOccurrenceType.SESSION]: "Sessions",
        [EventOccurrenceType.TOUR]: "Tours",
      });
    });

    const isMultiple = computed(() => {
      return data.value.occurrence_type !== EventOccurrenceType.STANDARD;
    });

    return {
      title,
      isMultiple,
    };
  },
});
</script>
